import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import moment from 'moment';
import Footer from '../layout/Footer';

const styles = theme => ({
    media: {
        height: '0',
        marginBottom: '35px',
        maxWidth: '100%',
        display: 'block',
        paddingTop: '56.25%',
    },  
    container: {
        paddingTop: 35,
        marginRight: 'auto',
        marginLeft: 'auto',
        
        [theme.breakpoints.up('md')]: {
            width: 920,
        },
        [theme.breakpoints.up('lg')]: {
            width: 1170,
        },
        [theme.breakpoints.up('xl')]: {
            width: 1366,
        },
    },
    mediaPadding: {
        paddingBottom: 40,
    },
    paragraph: {
        fontFamily: 'Open Sans',
        letterSpacing: '0.1px',
        color: '#666666',
        
    },
    sidePadding: {
        paddingRight: 15,
        paddingLeft: 15,
    },
    dateStyle: {
        fontFamily: 'Open Sans',
        color: '#a0a0a0',
        fontSize: 'small',
        paddingTop: '15px',
        paddingBottom: '15px',
    },
    aDesc: {
        color: 'black',
        textDecorationLine: 'none',
    }
});

const ShowEvent = (props) => { 
    const { classes, event } = props;
    return (
        <div>
            {!!event && <Grid className={classes.container}>
                <Grid className={classes.mediaPadding}>
                    <CardMedia className={classes.media} image={event.picUrl} />
                    <Typography variant="h5" color="inherit" align="center">
                        {event.title}
                    </Typography>
                    <Typography variant="body2" color="inherit" align="center" className={classes.dateStyle}>
                        {moment(event.date.toDate()).format('D MMM YYYY')}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        className={`${classes.paragraph} ${classes.sidePadding}`}
                        dangerouslySetInnerHTML={{__html: `${event.description}`}}
                    />
                </Grid>
                <Footer />
            </Grid>}
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    const urlSlug = ownProps.match.params.slug;
    const events = state.firestore.ordered.events;
    let eventPick;
    if (!!events){
        events.forEach((event) => {
            if (event.slug === urlSlug){
                eventPick = event;
            }
        });
    }
    return {
        event: eventPick,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles, { withTheme: true }),
    firestoreConnect([
        { collection: 'events' }
    ])
)(ShowEvent);
