import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  button: {
    marginLeft: '15px',
    marginTop: '10px',
  },
  input: {
    display: 'none',
  },
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  paragraph: {
    fontFamily: 'Open Sans',
    letterSpacing: '0.1px',
    color: '#666666',
  },
  emailStyle: {
    textDecoration: 'none',
    color: 'black',
  },
  privacyStyle: {
    fontSize: 'small',
    paddingLeft: '15px',
  },
  padBottom: {
    marginBottom: '10px',
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class Privacy extends Component {

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render(){
      const { classes } = this.props;
      return (
          <div className={classes.padBottom}>
            <Link className={`${classes.paragraph} ${classes.privacyStyle}`} onClick={this.handleOpen}>Privacy Policy</Link>
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.open}
                onClose={this.handleClose}
              >
                <div style={getModalStyle()} className={classes.paper}>
                  <Typography variant="subtitle1" className={classes.paragraph}>
                    Privacy Policy
                  </Typography>
                  <br></br>
                  <Typography variant="body2" className={classes.paragraph}>
                    Once you choose to register for an account or to make a purchase, you are not anonymous to us. By choosing to register for an account you consent to the transfer and storage of your account information. Information about our customers is an important part of our business, and we are not in the business of selling it to others. As indicated in this privacy policy, Beat Nations, will never sell, rent, share or otherwise disclose your information.<br></br><br></br>
                    Stripe or PayPal handles all of the transaction details through our storefront, beatnations.com.<br></br><br></br>
                    The only personal information that Beat Nations does receive is your order information, which includes your name, shipping address, billing address, email and phone number. Our primary purpose in collecting personal information is to provide you with a safe, efficient, and customised experience.<br></br><br></br>
                    All of our service providers have privacy policies which are consistent with our intent.<br></br><br></br>
                    When Will Beat Nations Share Information:<br></br><br></br>
                    Beat Nations will share information with our partners in the ordinary functioning of our business as disclosed by this policy statement and in the policy statements of our partners. We will disclose information to law enforcement with respect to aiding in the event of any unlawful activity, including but not limited to fraud and theft.<br></br><br></br>
                    Changes to this Privacy Policy:<br></br><br></br>
                    We reserve the right to modify this privacy statement at any time, so please review it frequently. If we make material changes to this policy, we will notify you here or by means of a notice on our homepage so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.<br></br><br></br>
                    Questions still not answered? Email us <a href="mailto:team@beatnations.com" className={classes.emailStyle}>here</a>
                  </Typography>
                </div>
               
              </Modal>
          </div>
      )
  }
}

export default withStyles(styles, { withTheme: true })(Privacy);
