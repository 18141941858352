import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import Button from '@material-ui/core/Button';

const SignedInLinks = (props) => {
    return (
        <React.Fragment>
            <Button color="inherit">
                <NavLink to="/create-event">New Event</NavLink>
            </Button>
            <Button color="inherit" onClick={props.signOut}><NavLink to="/">Log Out</NavLink></Button>
        </React.Fragment>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignedInLinks);