import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/fontawesome-free-brands';
import { faFacebookMessenger } from '@fortawesome/fontawesome-free-brands'; 
import { faWeibo } from '@fortawesome/fontawesome-free-brands'; 
import { faYoutube } from '@fortawesome/fontawesome-free-brands';

const styles = theme => ({
  icon: {
    color: '#666666',
  }
});

class SocialIcon extends Component {

  renderSwitch(name, classes) {
    switch(name) {
      case 'Instagram':
        return <FontAwesomeIcon icon={faInstagram} color="white" className={classes.icon} size="2x" />;
      case 'Facebook Messenger':
        return <FontAwesomeIcon icon={faFacebookMessenger} color="white" className={classes.icon} size="2x" />;  
      case 'Youtube':
        return <FontAwesomeIcon icon={faYoutube} color="white" className={classes.icon} size="2x" />;  
      case 'Weibo':
        return <FontAwesomeIcon icon={faWeibo} color="white" className={classes.icon} size="2x" />; 
      default:
        return 'link';
    }
  }
  
  render() {
    const { artist, classes } = this.props;
    
    return (
      <React.Fragment>
        <CardActions>
            {artist.socialUrl.map((info, index) => 
              <Button size="medium" color="primary" rel="noopener" aria-label={`Go to ${info.name}`} target="_blank" href={info.url} key={index}>
                {this.renderSwitch(info.name, classes)}
              </Button>
            )}
        </CardActions>
      </React.Fragment>       
              
    )
  }
}

export default withStyles(styles, { withTheme: true })(SocialIcon);