import React from 'react';
import SignedInLinks from './SignInLink';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/fontawesome-free-brands';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  brandName: {
    textDecoration: 'none',
    color: 'black',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  noShadow: {
    boxShadow: 'none',
    height: '55px',
    backgroundColor: 'black',
  },
  emailIcon: {
    textAlign: 'right',
    paddingRight: '10px',
  },
  instaIcon: {
    textAlign: 'right',
    marginRight: '9px',
  }
});

class Navbar extends React.Component {

  constructor(props) {
    super(props);
    this.state = { open: false, };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { auth, profile, classes } = this.props;
    const { open } = this.state;
    const links = auth.uid ? <SignedInLinks /> : null;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default" className={classes.noShadow}>
          <div className={classes.emailIcon}>
            <IconButton color="primary" aria-label="Instagram Icon" component="span">
              <a href="https://www.instagram.com/beatnationsmusic/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram">
                <FontAwesomeIcon icon={faInstagram} color="white" className={classes.instaIcon} size="lg" />
              </a>
            </IconButton>
            <IconButton color="primary" aria-label="Email" component="span">
              <a href="https://www.suprfan.co/3Zc" target="_blank" rel="noopener noreferrer" aria-label="Join our mailing list">
                <FontAwesomeIcon icon="envelope" color="white" size="lg" />
              </a>
            </IconButton>
          </div>
          <Toolbar>
            {links}
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(Navbar));