import React, { Component } from 'react';
import CenteredTabs from '../layout/SubNav';
import Navbar from '../layout/Navbar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  brandName: {
      textDecoration: 'none',
      color: 'black',
    },
  heading: {
    paddingTop: '15px',
    fontSize: '2.4rem',
    fontWeight: '600',
    letterSpacing: '1.5px',
  },  
});

class TopSection extends Component {
  render(){
    const { classes } = this.props;
    
    return(
      <React.Fragment>
        <Navbar />
        <style>{'body { background-color: #fff; }'}</style>
        <Link to="/" className={classes.brandName}>
        <Typography variant="h6" color="inherit" align="center" className={classes.heading}>
          BEAT NATIONS
        </Typography>
        </Link>
        <CenteredTabs />
      </React.Fragment>
    );
  }

}

export default withStyles(styles, { withTheme: true })(TopSection);;
