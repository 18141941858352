import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Footer from '../layout/Footer';
import moment from 'moment';
import { Link } from 'react-router-dom';

const styles = theme => ({
    media: {
        height: 200,
    },
    container: {
        paddingTop: 35,
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingRight: 15,
        paddingLeft: 15,
        [theme.breakpoints.up('md')]: {
            width: 920,
        },
        [theme.breakpoints.up('lg')]: {
            width: 1170,
        },
        [theme.breakpoints.up('xl')]: {
            width: 1366,
        },
    },
    mediaPadding: {
        paddingBottom: 40,
    },
    paragraph: {
        fontFamily: 'Open Sans',
        letterSpacing: '0.1px',
        color: '#666666',
    },
    sidePadding:{
      
    },
    fontBio: {
        fontSize: 'small',
    },
    marginBottom: {
        marginBottom: '20px',
    },
    aTag: {
        textDecorationLine: 'none',
        color: 'black',
    }
});

class Event extends Component {
    limitChar = (event) => {
        return event.description.substring(0, 55) + "...";
    }

    render(){
        const { events, classes } = this.props;
        return (
            <div>
                <Grid className={classes.container}>
                    <Grid container spacing={16} className={classes.marginBottom}>
                            {events && events.map((event) =>
                            <Grid item lg={3} md={4} sm={6} xs={12} className={classes.mediaPadding} key={event.id}>
                                <Card>
                                    <Link to={{pathname: `/event/${event.slug}`}} className={classes.aTag}>
                                    <CardActionArea>
                                        <CardMedia
                                        className={classes.media}
                                        image={event.picUrl}
                                        title={event.title}
                                        />
                                        <CardContent>
                                            <Typography variant="h5" component="h3">
                                                {event.title}
                                            </Typography>
                                            <Typography className={classes.paragraph} gutterBottom variant="body2" component="h3">
                                                {moment(event.date.toDate()).format('D MMM YYYY')}
                                            </Typography>
                                            <Typography className={`${classes.paragraph} ${classes.fontBio}`} dangerouslySetInnerHTML={{ __html: this.limitChar(event) }} />                              
                                        </CardContent>
                                    </CardActionArea>
                                    </Link>
                                </Card>
                            </Grid>
                            )}
                    </Grid>
                    <Footer />
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        events: state.firestore.ordered.events,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles, { withTheme: true }),
    firestoreConnect([
        { collection: 'events', orderBy: ['date', 'desc'] },
    ])
)(Event);