import React, { Component } from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Footer from '../layout/Footer';
import SocialIcon from './SocialIcon';

const styles = theme => ({
    media: {
        height: 200,
    },
    container: {
        paddingTop: 35,
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingRight: 15,
        paddingLeft: 15,
        [theme.breakpoints.up('md')]: {
            width: 920,
        },
        [theme.breakpoints.up('lg')]: {
            width: 1170,
        },
        [theme.breakpoints.up('xl')]: {
            width: 1366,
        },
    },
    mediaPadding: {
        paddingBottom: 40,
    },
    paragraph: {
        fontFamily: 'Open Sans',
        letterSpacing: '0.1px',
        color: '#666666',
    },
    sidePadding:{
      
    },
    fontBio: {
        fontSize: 'smaller',
    },
    marginBottom: {
        marginBottom: '20px',
    }
});

class Artists extends Component {
    render(){
        const { artists, classes } = this.props;
        
        return (
            <div>
                <Grid className={classes.container}>
                    <Grid container spacing={16} className={classes.marginBottom}>
                            {artists && artists.map((artist) =>
                            <Grid item xs={12} md={4} sm={6} className={classes.mediaPadding} key={artist.id}>
                                <Card>
                                    <CardMedia
                                    className={classes.media}
                                    image={artist.profilePicUrl}
                                    title={artist.artistName}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h3">
                                            {artist.artistName}
                                        </Typography>
                                        <p className={`${classes.paragraph} ${classes.fontBio}`} dangerouslySetInnerHTML={{ __html: artist.artistBio }} />                                    
                                    </CardContent>
                                    <SocialIcon artist={artist} />
                                </Card>
                            </Grid>
                            )}
                    </Grid>
                    <Footer />
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        artists: state.firestore.ordered.artists,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(styles, { withTheme: true }),
    firestoreConnect([
        { collection: 'artists', orderBy: ['orderBy', 'asc'] },
    ])
)(Artists);
