import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';

const styles = {
  root: {
    flexGrow: 1,
  },
  subSpacing: {
    letterSpacing: '1.5px',
  },
  noLinkStyle: {
    textDecoration: 'none',
    color: 'black',
  }
};

class CenteredTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: 0};
  }
 
  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    
    return (
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          centered
          onChange={this.handleChange}
        >
          <Tab
            disableRipple
            label="About"
            className={classes.subSpacing}
            to={'/'}
            component={Link}
          />
          {/* <Tab
            disableRipple
            label="Case Studies"
            className={classes.subSpacing}
            to={'/casestudies'}
            component={Link}
          />   */}
          <Tab
            disableRipple
            label="Artists"
            className={classes.subSpacing}
            to={'/artists'}
            component={Link}
          />
          <Tab
            disableRipple
            label="Event"
            className={classes.subSpacing}
            to={'/event'}
            component={Link}
          />
          <Tab 
            disableRipple
            label="Contact Us"
            className={classes.subSpacing}
            component={Link}
            to="/contact"
          />
        </Tabs>
    );
  }
}

CenteredTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CenteredTabs);