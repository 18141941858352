import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Homepage from './components/view/Homepage';
import Contact from './components/view/Contact';
import Artists from './components/view/Artists';
import TopSection from './components/view/TopSection';
import Event from './components/view/Event';
import SignIn from './components/auth/SignIn';
import CreateEvent from './components/pages/CreateEvent';
import CssBaseline from '@material-ui/core/CssBaseline';
import NotFound from './components/view/NotFound'
import { MuiThemeProvider } from '@material-ui/core/styles';
import CustomTheme from './style/CustomTheme';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/fontawesome-free-brands';
import { faFacebookMessenger } from '@fortawesome/fontawesome-free-brands';
import { faWeibo } from '@fortawesome/fontawesome-free-brands';
import { faYoutube } from '@fortawesome/fontawesome-free-brands';
import ShowEvent from './components/pages/ShowEvent';

library.add(faEnvelope, faInstagram, faFacebookMessenger, faCheck, faLink, faWeibo, faYoutube);

class App extends Component {
  render() {

    return (
      <BrowserRouter>
        <React.Fragment>
          <CssBaseline />
          <MuiThemeProvider theme={CustomTheme}>
            <TopSection />
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route exact path="/contact" component={Contact} />
              <Route path="/artists" component={Artists} />
              <Route path="/event/:slug" component={ShowEvent} />
              <Route path="/event" component={Event} />
              <Route path="/create-event" component={CreateEvent} />
              <Route path="/signin" component={SignIn} />
              <Route component={NotFound} />
            </Switch>
          </MuiThemeProvider>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}

export default App;
