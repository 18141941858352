import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createEvent } from '../../store/actions/eventActions';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
  });

class CreateProject extends Component {
    state = {
        title: '',
        slug: '',
        picUrl: '',
        date: '',
        description: '',
        isFeatured: false,
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createEvent(this.state);
        this.props.history.push('/');
    }
    handleCheckboxChange = (e) => {
        this.setState({
            isFeatured: !this.state.isFeatured,
        });
    }
    handleChangeTime = (e) => {
        this.setState({
            date: e.target.value + "T00:00:00",
        });
    }
    render(){
        const { auth, classes } = this.props;
        if (!auth.uid) return <Redirect to="/signin" />

        return(
            <div className="container">
                <form onSubmit={this.handleSubmit} className="white">
                    <h5 className="grey-text text-darken-3">Create New Event</h5>
                    <div className="input-field">
                        <label htmlFor="title">Title</label>
                        <input type="text" id="title" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="title">Slug</label>
                        <input type="text" id="slug" onChange={this.handleChange}/>
                    </div>
                    <div className="input-field">
                        <label htmlFor="title">Pic Url</label>
                        <input type="text" id="picUrl" onChange={this.handleChange}/>
                    </div>
                    <TextField
                        onChange={this.handleChangeTime}
                        id="date"
                        label="Event Date"
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                    <div className="input-field">
                        <label htmlFor="description">Description</label>
                        <textarea className="materialize-textarea" onChange={this.handleChange} id="description"></textarea>
                    </div>
                    <FormGroup row>
                        <FormControlLabel
                        control={
                            <Checkbox
                            defaultChecked={this.state.isFeatured}
                            onChange={this.handleCheckboxChange}
                            id="isFeatured"
                            />
                        }
                        label="Featured Event"
                        />
                    </FormGroup>    
                    <div className="input-field">
                        <button className="btn pink lighten-1 z-depth-0">Create</button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStatetoProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createEvent: (event) => dispatch(createEvent(event))
    }
}

export default compose(
    connect(mapStatetoProps, mapDispatchToProps),
    withStyles(styles, { withTheme: true }),
)(CreateProject);