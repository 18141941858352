import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer';

const HOME_IMAGE_WEBP = "https://firebasestorage.googleapis.com/v0/b/beat-nations-fire.appspot.com/o/website%2FhomeImage.webp?alt=media&token=a7e26107-1bdd-4f4a-ad9c-90925afdde79";
const HOME_IMAGE_JPEG = "https://firebasestorage.googleapis.com/v0/b/beat-nations-fire.appspot.com/o/website%2FhomeImage.jpg?alt=media&token=7f8aad1c-6c65-4bd3-a7a7-fe4c65b3e598";

const styles = theme => ({
  media: {
    height: '0',
    marginBottom: '35px',
    maxWidth: '100%',
    display: 'block',
    paddingTop: '56.25%',
  },
  container: {
    paddingTop: 35,
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 920,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1170,
    },
    [theme.breakpoints.up('xl')]: {
      width: 1200,
    },
  },
  mediaPadding: {
    paddingBottom: 40,
  },
  paragraph: {
    fontFamily: 'Open Sans',
    letterSpacing: '0.1px',
    color: '#666666',

  },
  sidePadding: {
    paddingRight: 15,
    paddingLeft: 15,
  },
  pictureImg: {
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
  },
  pictureImgContainer: {
    marginBottom: '35px',
  },
});

class Homepage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid className={classes.container}>
          <Grid className={classes.mediaPadding}>
            <div className={classes.pictureImgContainer}>
              <picture>
                <source srcSet={HOME_IMAGE_WEBP} type="image/webp" />
                <source srcSet={HOME_IMAGE_JPEG} type="image/webp" />
                <img className={classes.pictureImg} src={HOME_IMAGE_JPEG} alt="" />
              </picture>
            </div>
            <Typography variant="body2" color="textSecondary" className={`${classes.paragraph} ${classes.sidePadding}`}>
              Beat Nations is a unique bridge between UK and China. Supporting the next generation of British and Chinese creative, through an all year round events and campaigns, the aim is to promote, produce, facilitate and develop performing and recording artists, composers, lyricists, DJs, session musicians and music producers across the borders.<br></br><br></br>In the past, artists and producers that worked with Beat Nations went on to success in both countries – from working in featured film, to TV series such as The Voice (China) and I’m A Singer (Chinese National TV), as well as Hong Kong’s TVB and in U.K. performing in Bestival, Southbank and Trafalgar Square.
            </Typography>
          </Grid>
          <Footer />
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Homepage);
