import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Footer from '../layout/Footer'
import JoinUs from './JoinUs';
import Privacy from './Privacy';

const CONTACTUS_IMAGE_WEBP = "https://firebasestorage.googleapis.com/v0/b/beat-nations-fire.appspot.com/o/website%2FcontactUs.webp?alt=media&token=c2c41893-026b-4f77-a2a5-7e3462189006";
const CONTACTUS_IMAGE_JPEG = "https://firebasestorage.googleapis.com/v0/b/beat-nations-fire.appspot.com/o/website%2FcontactUs.jpg?alt=media&token=22f5e651-5a14-4573-8e0f-fb5021b5ece3";

const styles = theme => ({
  media: {
    height: '0',
    maxWidth: '100%',
    display: 'block',
    paddingTop: '56.25%',
    marginTop: '15px',
  },
  container: {
    paddingTop: 35,
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.up('md')]: {
      width: 920,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1170,
    },
    [theme.breakpoints.up('xl')]: {
      width: 1366,
    },
  },
  mediaPadding: {
    paddingBottom: 30,
  },
  paragraph: {
    fontFamily: 'Open Sans',
    letterSpacing: '0.1px',
    color: '#666666',
  },
  emailStyle: {
    textDecoration: 'none',
    color: 'black',
  },
  sidePadding: {
    paddingRight: 15,
    paddingLeft: 15,
  },
  padTop: {
    paddingTop: 15,
  },
  pictureImg: {
    width: '100%',
    display: 'block',
    marginTop: '-10%',
    marginBottom: '-5%',
  },
  pictureImgContainer: {
    marginTop: '15px',
    overflow: 'hidden',
  },
});

class Contact extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid className={classes.container}>
          <Grid className={classes.mediaPadding}>
            <Typography variant="body2" color="textSecondary" className={`${classes.paragraph} ${classes.sidePadding}`}>
              Thank you for your interest.<br></br><br></br>
              Booking: <a href="mailto:booking@beatnations.com" className={classes.emailStyle}>booking@beatnations.com</a><br></br>
              General Enquiry: <a href="mailto:team@beatnations.com" className={classes.emailStyle}>team@beatnations.com</a><br></br>
              Media/Press/PR: <a href="mailto:media@beatnations.com" className={classes.emailStyle}>media@beatnations.com</a><br></br>
            </Typography>
            <JoinUs />
            <div className={classes.pictureImgContainer}>
              <picture>
                <source srcSet={CONTACTUS_IMAGE_WEBP} type="image/webp" />
                <source srcSet={CONTACTUS_IMAGE_JPEG} type="image/webp" />
                <img className={classes.pictureImg} src={CONTACTUS_IMAGE_JPEG} alt="" />
              </picture>
            </div>
          </Grid>
          <Privacy />
          <Footer />
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Contact);
