 import firebase from 'firebase/app'
 import 'firebase/firestore'
 import 'firebase/auth'

 var config = {
    apiKey: "AIzaSyBpz05uk8ZkW_4IQZRM2Sx9eoTPsa8IZEk",
    authDomain: "beat-nations-fire.firebaseapp.com",
    databaseURL: "https://beat-nations-fire.firebaseio.com",
    projectId: "beat-nations-fire",
    storageBucket: "beat-nations-fire.appspot.com",
    messagingSenderId: "981759340888"
  };

  firebase.initializeApp(config);

  export default firebase;