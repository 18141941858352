import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Playfair Display',
      'Open Sans'
    ].join(','),
  },
  palette: {
    primary: {
      light: '#ffffff',
      main: '#000000',
      dark: '#bcbcbc',
      contrastText: '#000000',
    },
    secondary: {
      light: '#ffff72',
      main: '#ffeb3b',
      dark: '#c8b900',
      contrastText: '#000000',
    },
    type: 'light',
  },
});

export default theme;