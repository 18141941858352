const initState = {
    authError: null
};

const authReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOGIN_ERROR':
            console.log("errrorrr");
            return {
                ...state,
                authError: "Login failed"
            }
        case 'LOGIN_SUCCESS':
            console.log("key to success");
            return {
                ...state,
                authError: null
            }
        case 'SIGNOUT_SUCCESS':
            console.log("sign out success");
            return state;
        case 'SIGNUP_SUCCESS':
            console.log('sign up success');
            return {
                ...state,
                authError: null
            }
        case 'SUGNUP_ERROR':
            console.log('sign up error');
            return {
                ...state,
                authError: action.err.message
            }        
        default:
            return state;
    }
}

export default authReducer