import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = theme => ({
  button: {
    marginLeft: '15px',
    marginTop: '10px',
  },
  input: {
    display: 'none',
  },
  paper: {
    position: 'absolute',
    width: '80%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  paragraph: {
    fontFamily: 'Open Sans',
    letterSpacing: '0.1px',
    color: '#666666',
  },
  emailStyle: {
    textDecoration: 'none',
    color: 'black',
  }
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class JoinUs extends Component {

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render(){
      const { classes } = this.props;
      return (
          <div>
              <Button variant="outlined" className={`${classes.button} ${classes.paragraph}`} onClick={this.handleOpen}>
                Join Us
              </Button>
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.open}
                onClose={this.handleClose}
              >
                <div style={getModalStyle()} className={classes.paper}>
                  <Typography variant="subtitle1" id="modal-title" className={classes.paragraph}>
                    Why Join Us?
                  </Typography>
                  <div className={classes.demo}>
                    <List>
                      <ListItem>
                        <ListItemIcon>
                        <FontAwesomeIcon icon="check" color="#666666" />
                        </ListItemIcon>
                        <Typography variant="body2" className={classes.paragraph}>Gain experience from an awarding winning production team</Typography>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                        <FontAwesomeIcon icon="check" color="#666666" />
                        </ListItemIcon>
                        <Typography variant="body2" className={classes.paragraph}>Benefit from our network and partners, meet new friends</Typography>
                      </ListItem>                       
                    </List>
                    <Typography variant="subtitle1" className={classes.paragraph}>
                      Area of opportunities includes:
                    </Typography>
                    <ul>
                      <li className={classes.paragraph}><Typography variant="body2" className={classes.paragraph}>Audio/Visual</Typography></li>
                      <li className={classes.paragraph}><Typography variant="body2" className={classes.paragraph}>Music</Typography></li>
                      <li className={classes.paragraph}><Typography variant="body2" className={classes.paragraph}>Arts/Style</Typography></li>
                      <li className={classes.paragraph}><Typography variant="body2" className={classes.paragraph}>Event</Typography></li>
                      <li className={classes.paragraph}><Typography variant="body2" className={classes.paragraph}>Technology</Typography></li>
                      <li className={classes.paragraph}><Typography variant="body2" className={classes.paragraph}>Social Media/PR</Typography></li>
                      <li className={classes.paragraph}><Typography variant="body2" className={classes.paragraph}>Genius</Typography></li>
                    </ul>
                    <Typography variant="body2" className={classes.paragraph}>Email your CV, portfolio and/or links to <a href="mailto:team@beatnations.com" className={classes.emailStyle}>team@beatnations.com</a>, tell us why you wanted to join us and we will get back to you.</Typography>
                  </div>
                </div>
              </Modal>
          </div>
      )
  }
}

export default withStyles(styles, { withTheme: true })(JoinUs);
