export const createEvent = (event) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        firestore.collection('events').add({
            ...event,
            date: firebase.firestore.Timestamp.fromDate(new Date(event.date)),
        }).then(() => {
            dispatch({ type: 'CREATE_EVENT', event });
        }).catch((err) => {
            dispatch({type: 'CREATE_EVENT_ERROR', err});
        })
    }
};