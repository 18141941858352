import React, { Component } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const getYear = (new Date()).getFullYear();

const styles = () => ({
  paragraph: {
    fontFamily: 'Open Sans',
    letterSpacing: '0/2px',
    paddingBottom: '10px',
  },
  sidePadding:{
    paddingRight: 15,
    paddingLeft: 15,
  }
});

class Footer extends Component {
  render(){
    const { classes } = this.props;
    return (
      <div className={classes.sidePadding}>
      <Divider />
        <Typography varient="h6" color="textSecondary" className={classes.paragraph}>© {getYear} BEAT NATIONS. All Rights Reserved.</Typography>
      </div>  
    )
  }
}

export default withStyles(styles, { withTheme: true })(Footer);